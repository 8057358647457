import React from 'react';
import coverlogo from '../../../../extensions/images/redesign/footer/happy-house.svg'
import './styles.css';

const DEFAULT_WIDGET_TEXT = ' •  Where dreams live  •  Where dreams live  •  Where dreams live ';

const FooterLogoBox = (props) => {
  const { widget } = props;
  return (
    <div className="footer-logo-box">
      <div className="rotation-box">
        <svg 
          className="rounded-text" 
          style={{
            letterSpacing: widget && widget.letterSpacing ? widget.letterSpacing + 'px' : '0.6px',
            fontSize: widget && widget.fontSize ? widget.fontSize + 'px' : '14px',
          }}
          viewBox="0 0 200 200"
        >
          <path
            id="textPath"
            d="M 85,0 A 85,85 0 0 1 -85,0 A 85,85 0 0 1 85,0"
            transform="translate(100,100)"
            fill="none"
            strokeWidth="0"
          ></path>
          <g>
            <text textAnchor="start">
              <textPath href="#textPath" startOffset="0%">
                {widget && widget.text ? widget.text : DEFAULT_WIDGET_TEXT}
              </textPath>
            </text>
          </g>
        </svg>

        <img
          className="no-rotation-image"
          height="167px"
          width="167px"
          src={coverlogo}
          alt="footer img rotation"
        />
      </div>
    </div>
  );
};

export default FooterLogoBox;
