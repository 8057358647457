import React from 'react';
import Slider from 'react-slick';
import Marquee from 'react-fast-marquee';

const PartnersSlider = (props) => {
  const config = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    arrows: false,
    lazyLoad: 'progressive',
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const images = [
    { src: '/extensions/images/partners-image/bbc-new.png' },
    { src: '/extensions/images/partners-image/business-new.png' },
    { src: '/extensions/images/partners-image/buy-new.png' },
    { src: '/extensions/images/partners-image/calvinayre-new.png' },
    { src: '/extensions/images/partners-image/daily_mail-new.png' },
    { src: '/extensions/images/partners-image/douthlondon-new.png' },
    { src: '/extensions/images/partners-image/eastate-new.png' },
    { src: '/extensions/images/partners-image/eugaming-new.png' },
    { src: '/extensions/images/partners-image/evening-new.png' },
    { src: '/extensions/images/partners-image/findmy-new.png' },
    { src: '/extensions/images/partners-image/houseb-new.png' },
    { src: '/extensions/images/partners-image/inside-new.png' },
    { src: '/extensions/images/partners-image/metro-new.png' },
    { src: '/extensions/images/partners-image/mirror-new.png' },
    { src: '/extensions/images/partners-image/moneymagpie-new.png' },
    { src: '/extensions/images/partners-image/portal_watch-new.png' },
    { src: '/extensions/images/partners-image/quax-new.png' },
    { src: '/extensions/images/partners-image/sundat_express-new.png' },
    { src: '/extensions/images/partners-image/the_assisted-new.png' },
    { src: '/extensions/images/partners-image/the_sun-new.png' },
    { src: '/extensions/images/partners-image/the_telegraph-new.png' },
    { src: '/extensions/images/partners-image/this_is_money-new.png' },
    { src: '/extensions/images/partners-image/ukf-new.png' },
    { src: '/extensions/images/partners-image/which-new.png' },
    { src: '/extensions/images/partners-image/mylondon-new.png' },
  ];

  let imagesArray = props.images ? props.images : images;
  if (props.isTicker) {
    return (
      <Marquee speed={70}>
        {imagesArray.map((x, i) => {
          return (
            <div key={i} data-index={i} className={props.isCharitySlider ? 'charity-rounded-image' : undefined}>
              <img
                alt="partner"
                src={x.src}
                style={{ marginLeft: props.isCharitySlider ? '0px' : '40px' }}
                className={x.isRounded ? 'rounded' : undefined}
              />
            </div>
          );
        })}
      </Marquee>
    );
  }
  return (
    <Slider
      {...config}
      className={props.isDark ? 'partnersSlider dark slick-custom-dots' : 'partnersSlider slick-custom-dots'}
    >
      {imagesArray.map((x, i) => {
        return (
          <div key={i} data-index={i} className={props.isCharitySlider ? 'charity-rounded-image' : undefined}>
            <img alt="partner" src={x.src} />
          </div>
        );
      })}
    </Slider>
  );
};

export default PartnersSlider;
