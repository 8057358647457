import React from "react";
import {
  DreamDrawAccordion,
  DreamDrawBlock,
  DreamDrawContent,
  DreamDrawDescription,
  DreamDrawImg,
} from "raffle-house-uikit";
import {
  dreamDrawAccordion,
  dreamDrawFeatures,
} from "../../../lib/static-data";
import './style.scss';

export default function DreamDraw() {
  return (
    <DreamDrawBlock>
      <DreamDrawContent
        title="Win this £2.7 million Kent Dream Home"
        features={dreamDrawFeatures}
      >
        <DreamDrawImg>
          <picture>
            <source
              srcSet={`${process.env.REACT_APP_AWS_S3_URL}dream-home-draw/dhd-15.08.2024-2x.jpg 1.5x, ${process.env.REACT_APP_AWS_S3_URL}dream-home-draw/dhd-15.08.2024.jpg`}
              media="(min-width: 576px)"
            />
            <source
              srcSet={`${process.env.REACT_APP_AWS_S3_URL}dream-home-draw/dhd-15.08.2024-sm-2x.jpg 1.5x, ${process.env.REACT_APP_AWS_S3_URL}dream-home-draw/dhd-15.08.2024-sm.jpg`}
            />
            <img
              src={`${process.env.REACT_APP_AWS_S3_URL}dream-home-draw/dhd-15.08.2024.jpg`}
              width={960}
              height={540}
              alt="dream draw"
            />
          </picture>
        </DreamDrawImg>
        <DreamDrawDescription
          text={[
            "This magnificent family home in Kent, worth £2,700,000 including a whopping £200,000 worth of furnishings, is the very definition of a Dream Home.",
            "With your own pool set within fabulous, extensive grounds, and no Stamp Duty to pay, you'll be living the dream in no time.",
            "Oh, and did we mention that you can also elect to take the tax-free cash alternative? It's time to dream big and do good. Our 15th Dream Home competition is here.",
          ]}
        />
      </DreamDrawContent>
      <DreamDrawAccordion items={dreamDrawAccordion} />
    </DreamDrawBlock>
  );
}
