import React from 'react';
import TitleText from "../common/TitleText/TitleText";
import PartnersSlider from "./PartnersSlider/PartnersSlider";
import partnersImage1 from '../../../extensions/images/redesign/partners-image-dark/bbc.png';
import partnersImage2 from '../../../extensions/images/redesign/partners-image-dark/buy.png';
import partnersImage3 from '../../../extensions/images/redesign/partners-image-dark/calvinayre.png';
import partnersImage4 from '../../../extensions/images/redesign/partners-image-dark/daily_mail.png';
import partnersImage5 from '../../../extensions/images/redesign/partners-image-dark/douthlondon.png';
import partnersImage6 from '../../../extensions/images/redesign/partners-image-dark/eastate.png';
import partnersImage7 from '../../../extensions/images/redesign/partners-image-dark/eugaming.png';
import partnersImage8 from '../../../extensions/images/redesign/partners-image-dark/evening.png';
import partnersImage9 from '../../../extensions/images/redesign/partners-image-dark/findmy.png';
import partnersImage10 from '../../../extensions/images/redesign/partners-image-dark/houseb.png';
import partnersImage11 from '../../../extensions/images/redesign/partners-image-dark/inside.png';
import partnersImage12 from '../../../extensions/images/redesign/partners-image-dark/metro.png';
import partnersImage13 from '../../../extensions/images/redesign/partners-image-dark/mirror.png';
import partnersImage14 from '../../../extensions/images/redesign/partners-image-dark/moneymagpie.png';
import partnersImage15 from '../../../extensions/images/redesign/partners-image-dark/portal_watch.png';
import partnersImage16 from '../../../extensions/images/redesign/partners-image-dark/quax.png';
import partnersImage17 from '../../../extensions/images/redesign/partners-image-dark/sundat_express.png';
import partnersImage18 from '../../../extensions/images/redesign/partners-image-dark/the_assisted.png';
import partnersImage19 from '../../../extensions/images/redesign/partners-image-dark/the_sun.png';
import partnersImage21 from '../../../extensions/images/redesign/partners-image-dark/the_telegraph.png';
import partnersImage22 from '../../../extensions/images/redesign/partners-image-dark/this_is_money.png';
import partnersImage23 from '../../../extensions/images/redesign/partners-image-dark/ukf.png';
import './styles.scss';

const PartnersBlock = (props) => {
  const imagesDark = [
    { src: partnersImage1 },
    { src: partnersImage2 },
    { src: partnersImage3 },
    { src: partnersImage4 },
    { src: partnersImage5 },
    { src: partnersImage6 },
    { src: partnersImage7 },
    { src: partnersImage8 },
    { src: partnersImage9 },
    { src: partnersImage10 },
    { src: partnersImage11 },
    { src: partnersImage12 },
    { src: partnersImage13 },
    { src: partnersImage14 },
    { src: partnersImage15 },
    { src: partnersImage16 },
    { src: partnersImage17 },
    { src: partnersImage18 },
    { src: partnersImage19 },
    { src: partnersImage21 },
    { src: partnersImage22 },
    { src: partnersImage23 },
  ];

  if (props.withoutContainer) {
    return (
      <section className={`partners-block-redesign ${props.withoutPaddingBottom ? 'no-pb' : ''}`}>
        <TitleText isCentered as="h2" variant={'primary'} color="color-5">
        In the news
        </TitleText>

        <div className="partners-wrapper">
          <div className="partners-block-redesign__content-inner">
            <PartnersSlider images={imagesDark} isDark={props.isDark} isTicker={props.isTicker} />
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <div className="partners-block-redesign">
        <TitleText isCentered as="h2" variant={'primary'} color="color-5">
        In the news
        </TitleText>

        <div className="partners-wrapper">
          <div className="container">
            <div className="partners-block-redesign__content-inner">
              <PartnersSlider images={imagesDark} isDark={props.isDark} isTicker={props.isTicker} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PartnersBlock;
