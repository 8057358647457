import React from "react";
import { useGSAP } from "@gsap/react";
import {
  Timeline,
  TimelineHeader,
  TimelineTitle,
  TimelineSubtitle,
  TimelineMain,
  TimelineSteps,
  TimelineButton,
} from "raffle-house-uikit";
import { startBadgeAnimation, startHeaderAnimation } from "./script";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
// import brainTumor from "../../../extensions/images/redesign/brain-tumor-light.png";
import brainTumor from "../../../extensions/images/redesign/charity/white_and_transparent.png";
import "./styles.scss";


const TimelineBlock = (props) => {
  const { data, widget } = props;
  const mobile = useMediaQuery("(max-width: 560px)");
  const laptop = useMediaQuery("(max-width: 1024px)");
  const title = laptop ? data.horizontal.title : data.vertical.title;
  const subtitle = mobile ? data.horizontal.subtitle : data.vertical.subtitle;
  useGSAP(() => !mobile && startHeaderAnimation());
  useGSAP(
    () => {
      !mobile &&
        startBadgeAnimation({
          numberOfSteps: data.vertical.steps.length,
          images: (widget && widget.images) || [],
        });
    },
    { dependencies: [mobile, widget] }
  );
  return (
    <Timeline>
      <TimelineHeader>
        <TimelineTitle>{title}</TimelineTitle>
        <TimelineSubtitle>{subtitle}</TimelineSubtitle>
      </TimelineHeader>
      <TimelineMain>
        <TimelineSteps
          widget={{ data: widget }}
          verticalTimelineSteps={data.vertical.steps}
          horizontalTimelineSteps={data.horizontal.steps}
        />
        <div className="timeline-block-footer">
          <h3 className="timeline-block-footer__header">
            Supporting When You Wish Upon a Star
          </h3>
          <img
            className="timeline-block-footer__img"
            src={brainTumor}
            alt="brain-tumor"
          />
          <p className="timeline-block-footer__text">
            Every time you enter, you'll be supporting When You Wish Upon a Star.
            It's time to dream big and do good.
          </p>
        </div>
      </TimelineMain>
    </Timeline>
  );
};

export default TimelineBlock;
