import React, { useState, useEffect } from 'react';
import { dateDiff } from "../../../../utils";
import './styles.css';

const MobileHeaderCountdown = (props) => {
  const { endsAt } = props;
  const { prizeType } = props;
  const [state, setState] = useState({
    Days: '--',
    Hours: '--',
    Minutes: '--',
    Seconds: '--',
  });

  function getTime() {
    const endRaffleDate = new Date(endsAt);
    const now = Date.now();
    const date = dateDiff(now, endRaffleDate, prizeType);
    return date;
  }
  let interval;
  useEffect(() => {
    const now = new Date().getTime() / 1000;
    const endsAtUnix = new Date(endsAt).getTime() / 1000;

    if (endsAt && endsAtUnix > now) {
      interval = setInterval(() => {
        const date = getTime();
        setState(date);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  });

  const renderTimeString = () => {
    const params = {
      Days: 'DAYS',
      Hours: 'HRS',
      Minutes: 'MIN',
      Seconds: 'SEC',
    };
    let value = ' ';
    {
      Object.keys(state).map((k, i) => {
        if (k === 'Mths' && state['Mths'] === '00') {
          return null;
        }
        value += `${state[k].substring(0, 1)}${state[k].substring(
          1,
          state[k].length
        )}${params[k]} `;
      });
    }
    return ` ${value}`;
  };

  return (
    <div className='mobile-header-countdown'>
      <p>
        {props.text}<br />
        <span>{renderTimeString()}</span>
      </p>
    </div>
  );
};

export default MobileHeaderCountdown;
