import React from 'react';
import './styles.scss';

const TitleText = ({ as, color, variant, isCentered, ...props }) => {
  const colorStyle = color ? ` ${color}` : ' color-1';
  const variantType = variant ? `${variant}-title` : 'primary-title';
  let className = variantType + colorStyle + (isCentered ? ' centered' : '');
  function parseVariants(as) {
    switch (as) {
      case 'h1': {
        return <h1 className={className}>{props.children}</h1>;
      }
      case 'h2': {
        return <h2 className={className}>{props.children}</h2>;
      }
      case 'h3': {
        return <h3 className={className}>{props.children}</h3>;
      }
      case 'h4': {
        return <h4 className={className}>{props.children}</h4>;
      }
      case 'h5': {
        return <h5 className={className}>{props.children}</h5>;
      }
      case 'h6': {
        return <h6 className={className}>{props.children}</h6>;
      }
      default: {
        return <h1 className={className}>{props.children}</h1>;
      }
    }
  }
  return parseVariants(as);
};

export default TitleText;
