import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import dayjs from 'dayjs';
import { dateDiff } from "../../../utils";
import './styles.scss';


const CountDownString = (props) => {
  const { endsAt } = props;
  const { prizeType } = props;
  const [state, setState] = useState({
    Days: '--',
    Hours: '--',
    Minutes: '--',
    Seconds: '--',
  });

  function getTime() {
    const endRaffleDate = new Date(endsAt);
    const now = Date.now();
    const date = dateDiff(now, endRaffleDate, prizeType);
    return date;
  }
  let interval;
  useEffect(() => {
    const now = new Date().getTime() / 1000;
    const endsAtUnix = new Date(endsAt).getTime() / 1000;

    if (endsAt && endsAtUnix > now) {
      interval = setInterval(() => {
        const date = getTime();
        setState(date);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  });

  const renderTimeString = () => {
    let value = ' ';
    {
      Object.keys(state).map((k, i) => {
        if (k === 'Mths' && state['Mths'] === '00') {
          return null;
        }
        value += ` ${state[k].substring(0, 1)}${state[k].substring(1, state[k].length)}${k[0]} `;
      });
    }
    // return ` ${value} (end time is 00:00:00 on feb 27)`;
    return ` ${value}`;
  };
  return <React.Fragment> {renderTimeString()}</React.Fragment>;
};

const BottomTickerSection = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 576;
  let speed = isMobile ? 100 : 200;

  const render = () => {
    let stepperCountdown = null;
    let isHome = true;
    if (props.raffles.length > 0) {
      if (isHome) {
        const raffle = props.raffles[0];
        let activeRaffle = raffle;
        const activeRaffles = props.raffles.filter((raffle) => new Date(raffle.endsAt) > new Date());
        if (activeRaffles.length > 0) {
          activeRaffle = activeRaffles[0];
        } else {
          activeRaffle = props.raffles[props.raffles.length - 1];
        }

        if (activeRaffle) {
          stepperCountdown = activeRaffle.stepperCountdown;
        }
      }
    }
    if (stepperCountdown != null && stepperCountdown.isActive && dayjs().isBefore(dayjs(stepperCountdown.endsAt))) {
      let value = stepperCountdown.title;

      return (
        <Marquee className={`bottom-ticker-section ${props.variant ? props.variant : ''}`} speed={speed}>
          <p>
            NEXT DRAW CLOSING: <CountDownString prizeType="not-raffle" endsAt={stepperCountdown.endsAt} />
          </p>
          {/* </marquee> */}
        </Marquee>
      );
    }
    // if (stepperCountdown) {
    //   return (
    //     <Marquee className={`bottom-ticker-section ${props.variant ? props.variant : ''}`} speed={speed}>
    //       <p>
    //         NEXT DRAW CLOSING: <CountDownString prizeType="not-raffle" endsAt={stepperCountdown.endsAt} />
    //       </p>
    //       {/* </marquee> */}
    //     </Marquee>
    //   );
    // }
    return null;
  };
  return render();
  return (
    <Marquee className={`bottom-ticker-section ${props.variant ? props.variant : ''}`} speed={speed}>
      <p>
        NEXT DRAW CLOSING: <CountDownString prizeType="not-raffle" endsAt={new Date('2024-04-05')} />
      </p>
    </Marquee>
  );
};

export default BottomTickerSection;
