import { toast } from 'react-toastify';
// The slice of the store to be used.
const slice = 'toastr';

// Shows an error message.
const error = (message) => {
  // return toastr.error('ERROR', message);
  const r =  toast.error(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
  console.log({r});
  return r
};

// Shows an info message.
const info = (message) => {
  // return toastr.info('INFO', message, {
  //   icon: (
  //     <div className='info-image-toaster'>
  //       <img
  //         className='forgot-image-like'
  //         src='/extensions/images/new-design-images/new-design-icon/like-big-new.svg'
  //         alt='like'
  //       />
  //     </div>
  //   ),
  // });
  return toast.info(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
};

// Shows a success message.
const success = (message) => {
  // return toastr.success('SUCCESS', message);
  return toast.success(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
};

// Shows a warning message.
const warning = (message) => {
  // return toastr.warning('WARNING', message);
  return toast.warning(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
};

export { error, info, slice, success, warning };
