import React from "react";
import LazyLoad from "react-lazyload";
import { SectionWrapper } from "raffle-house-uikit";
import Header from "../Header/Header";
import HowItWorks from "../HowItWorks/HowItWorks";
import TimelineBlock from "../TimelineBlock/TimelineBlock";
import PartnersBlock from "../PartnersBlock/PartnersBlock";
import WinnerClub from "../WinnerClub/WinnerClub";
import Footer from "../Footer/Footer";
import MobileCTAButtonBottom from "../common/MobileCTAButtonBottom/MobileCTAButtonBottom";
import BottomTickerSection from "../BottomTickerSection/BottomTickerSection";
import BottomBigCTAButtonSection from "../common/BottomBigCTAButtonSection/BottomBigCTAButtonSection";
import Hero from '../Hero/Hero';
import BonusDraw from '../BonusDraw/BonusDraw';
import DreamDraw from '../DreamDraw/DreamDraw';
import Gallery from '../Gallery/Gallery';
import Testimonials from '../Testimonials/Testimonials';
import Faq from '../Faq/Faq'
import Charity from "../Charity/Charity";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import "./styles.scss";

const Main = (props) => {
  const {
    prize,
    prizeId,
    raffles,
    raffle,
    reviews,
    data,
    onCTAClick,
    onLearnMoreClick,
    onLearnMoreCharityClick,
    onLearnMoreBonusDrawClick,
    onMoreWinnersClick,
    widgets,
    winners,
  } = props;
  const isMobile = useMediaQuery("(max-width: 575px)");

  return (
    <>
      {prizeId ? (
        <div className="app">
          <Header raffle={raffle} onCTAClick={onCTAClick} />
          <main>
            <Hero />
            {!isMobile && (
              <SectionWrapper bg="bg-1">
                <Charity/>
              </SectionWrapper>
            )}
            <SectionWrapper bg="bg-1">
              <DreamDraw />
            </SectionWrapper> 
            {isMobile && (
              <SectionWrapper bg="bg-1">
                <Charity/>
              </SectionWrapper>
            )}
            <SectionWrapper bg="bg-1">
              <Gallery />
            </SectionWrapper>
            {/* <SectionWrapper bg="bg-2">
              <BonusDraw onCTAClick={onCTAClick}/>
            </SectionWrapper> */}
            <SectionWrapper bg='bg-2'>
              <TimelineBlock data={data.stepper} widget={widgets.timelineWidget} onCTAClick={onCTAClick}/>
            </SectionWrapper>
            <SectionWrapper bg="bg-3">
              <Testimonials 
                winners={winners} 
                onMoreWinnersClick={onMoreWinnersClick}
              />
            </SectionWrapper>
            <SectionWrapper bg="bg-3">
              <HowItWorks
                onCTAClick={onCTAClick}
              />
            </SectionWrapper>
            <SectionWrapper bg="bg-3">
              <Faq />
            </SectionWrapper>
            {/* {reviews && reviews.length ? (
              <SectionWrapper bg='bg-1'>
                <TrustpilotBlock reviews={reviews} />
              </SectionWrapper>
            ) : null} */}
            {/* <SectionWrapper bg="bg-1">
              <LivePollBlock />
            </SectionWrapper> */}
            <SectionWrapper bg="bg-3">
              <PartnersBlock isTicker withoutContainer />
            </SectionWrapper>
            <SectionWrapper bg="bg-1">
              <LazyLoad height={200}>
                <WinnerClub isDark />
              </LazyLoad>
            </SectionWrapper>
            <Footer insideComponent={true} widget={widgets.footerWidget} />
            <MobileCTAButtonBottom
              variant="variant-2"
              onClick={onCTAClick}
              currentRaffle={prize}
              raffles={raffles}
              withCountdown
            />
            <BottomTickerSection variant={"variant-1"} raffles={raffles} />
            <BottomBigCTAButtonSection onClick={onCTAClick} />
          </main>
        </div>
      ) : (
        <main className="no-prize">
          <h1>No prize</h1>
        </main>
      )}
    </>
  );
};

export default Main;
