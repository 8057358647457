import React from 'react'
import { CharityContainer, CharityContent, CharityImg, CharityTitle } from 'raffle-house-uikit/dist/cjs';
import image from '../../../extensions/images/redesign/charity/transparent_background.png';
import './styles.scss';

export default function Charity() {
  return (
    <div className='charity-block-wrap'>
      <CharityContainer>
        <CharityTitle>
          Dream big. <br className='mobile-spacer' /> Do good.
        </CharityTitle>
        <CharityContent type="single">
          <CharityImg image={image} />
        </CharityContent>
      </CharityContainer>
    </div>
  )
}
