const config = {
  NODE_ENV: process.env.NODE_ENV || 'development',
  APP_BASE_URL: process.env.REACT_APP_BASE_URL || 'http://localhost:8000',
  API_BASE_URL:
    process.env.REACT_APP_API_BASE_URL || 'http://localhost:8888/api',
  AWS_S3_URL: process.env.REACT_APP_AWS_S3_URL || '',
  APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID || ''
};

export default config;
