
// Twitter tracking
const twitterTrack = (eventId, payload) => {
  if (
    window.twq !== undefined &&
    typeof window.twq === 'function'
    // && process.env.NODE_ENV === 'production'
  ) {
    window.twq('event', eventId, payload);
  }
};

const twitterPageViewTrack = (pathname) => {
  console.log('Twitter Page View tracking event', process.env.REACT_APP_TWITTER_PIXEL_PAGE_VIEW_ID, pathname);
  twitterTrack(process.env.REACT_APP_TWITTER_PIXEL_PAGE_VIEW_ID, { pathname: pathname });
};

export default {
  twitterPageViewTrack,
};
