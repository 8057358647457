import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function headerAnimation() {
  const timeline = document.querySelector('.timeline-block');
  const timelineHeader = document.querySelector('.timeline-block-header');
  const timelinePoints = gsap.utils.toArray('.timeline-point');

  const mql1080 = matchMedia('(max-width: 1080px)');

  let timelinePointsTopTrigger = '55%';

  if (mql1080.matches) {
    timelinePointsTopTrigger = '46%';
  }

  ScrollTrigger.create({
    trigger: timeline,
    endTrigger: timelinePoints[timelinePoints.length - 1],
    start: 'top top',
    end: `50% ${timelinePointsTopTrigger}`,
    pin: timelineHeader,
    pinSpacing: false,
  });
}

function badgeAnimation({ numberOfSteps, images, scroller }) {
  const badge = document.getElementById('sticky-point-id');
  const badgeImage = badge.querySelector('.rotation-box__image');
  const timelinePoints = gsap.utils.toArray('.timeline-point');
  const timelineProgress = document.querySelector('.svg-timeline #timeline-progress');
  const verticalTimeline = document.querySelector('.vertical-timeline');
  const verticalTimelineTop = verticalTimeline.getBoundingClientRect().top;
  const timeline = document.querySelector('.timeline-block');
  const header = document.querySelector('.timeline-block-header');
  const headerHeight = header.getBoundingClientRect().height;

  const startPointTop = timelinePoints[0].getBoundingClientRect().top;
  const startPointHeight = timelinePoints[0].getBoundingClientRect().height;
  const endPointTop = timelinePoints[timelinePoints.length - 1].getBoundingClientRect().top;
  const endPointHeight = timelinePoints[timelinePoints.length - 1].getBoundingClientRect().height;

  const pathHeight = endPointTop + endPointHeight / 2 - (startPointTop + startPointHeight / 2);

  const mql1080 = matchMedia('(max-width: 1080px)');
  const mql575 = matchMedia('(max-width: 575px)');

  let offset = 80;
  let timelinePointsTopTrigger = '55%';
  let badgeStart = 20;
  let animationStart = 'top top';
  let endTrigger = timelinePoints[timelinePoints.length - 1];

  if (mql1080.matches) {
    offset = 90;
    badgeStart = 70;
    timelinePointsTopTrigger = '46%';
  }
  if (mql575.matches) {
    offset = 64;
    badgeStart = 46;
    timelinePointsTopTrigger = `${headerHeight + 20}px`;
    endTrigger = undefined;
  }
  let badgeEnd = endPointTop - verticalTimelineTop - offset;

  const badgeImages = Array.from({ length: numberOfSteps }).map((_, i) => {
    if (images[i]) {
      return `${process.env.REACT_APP_AWS_S3_URL}${images[i]}`;
    } else if (images[images.length - 1]) {
      return `${process.env.REACT_APP_AWS_S3_URL}${images[images.length - 1]}`;
    } else {
      return '';
    }
  });

  changeBadgeImage(0);

  ScrollTrigger.create({
    scroller,
    trigger: timeline,
    endTrigger: timelinePoints[timelinePoints.length - 1],
    start: animationStart,
    end: `50% ${timelinePointsTopTrigger}`,
    onEnter: () => {
      const badgeTop = badge.getBoundingClientRect().top;
      badge.style.position = 'fixed';
      badge.style.top = `${badgeTop}px`;
    },
    onEnterBack: () => {
      const badgeTop = badge.getBoundingClientRect().top;
      badge.style.position = 'fixed';
      badge.style.top = `${badgeTop}px`;
    },
    onLeave: () => {
      badge.style.position = 'absolute';
      badge.style.top = `${badgeEnd}px`;
    },
    onLeaveBack: () => {
      badge.style.position = 'absolute';
      badge.style.top = `-${badgeStart}px`;
    },
    onUpdate: (self) => {
      timelineProgress.setAttribute('y2', `${self.progress * pathHeight}`);
    },
  });

  timelinePoints.slice(1).forEach((point, index) => {
    const parent = point.parentElement;
    const textElements = parent.querySelectorAll('.timeline-text');
    ScrollTrigger.create({
      scroller,
      trigger: point,
      start: `50% ${timelinePointsTopTrigger}%`,
      onEnter: () => {
        point.classList.add('checked');
        textElements.forEach((element) => element.classList.add('active'));
        changeBadgeImage(index + 1);
      },
      onLeaveBack: () => {
        point.classList.remove('checked');
        textElements.forEach((element) => element.classList.remove('active'));
        changeBadgeImage(index);
      },
    });
  });

  function changeBadgeImage(pointIndex) {
    if (!images.length) return;
    badgeImage.innerHTML = `<img src="${badgeImages[pointIndex]}"/>`;
  }
}

function startHeaderAnimation() {
  setTimeout(headerAnimation, 2000)
}

function startBadgeAnimation(params) {
  setTimeout(() => badgeAnimation(params), 2000)
}

export { startHeaderAnimation, startBadgeAnimation };
