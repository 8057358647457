import React from 'react';
import { ToastContainer } from 'react-toastify';
import usePrize from '../hooks/usePrize';
import MetaTags from '../components/meta-tags';
import { doublePrizeData, singlePrizeData } from '../lib/static-data';
import Header from '../components/redesign/Header/Header';
import Main from '../components/redesign/Main/Main';
import CtaButtonLayout from '../components/redesign/CtaButtonLayout/CtaButtonLayout';
import PageLoader from '../components/redesign/PageLoader/PageLoader';
import Footer from '../components/redesign/Footer/Footer';
import useHomePageData from '../hooks/useHomePageData';
import useWidget from '../hooks/useWidget';
import useStackAdaptPixel from '../hooks/useStackAdaptPixel';
import { useLocation } from 'react-router-dom';
import { useTwitterHeadScript } from '../hooks/useTwitterHeadScript';
import tracking from "../helpers/tracking";

function App() {
  useStackAdaptPixel();
  useTwitterHeadScript();
  const location = useLocation();
  const { raffle, prizeId, prizeLoading, raffles, isExpired, activeRaffles, subscriptionModels, isDoublePrize } =
    usePrize();
  const { reviews, winners, isLoading: isHomePageDataloading } = useHomePageData();
  const { widget: timelineWidget, isWidgetLoading: isTimelineWidgetLoading } = useWidget({ type: 'timeline-logo-rotation-text' });
  const { widget: footerWidget, isWidgetLoading: isFooterWidgetLoading } = useWidget({ type: 'footer-logo-rotation-text' });

  React.useEffect(() => {
    tracking.twitterPageViewTrack(location.pathname);
  }, [location]);
  
  const onClickExternalLink = (endpoint) => {
    if (process.env.REACT_APP_BASE_URL) {
      window.open(process.env.REACT_APP_BASE_URL + endpoint, '_self');
    }
  }
  const onCTAClick = () => onClickExternalLink('/ticket-selector?charity=whenyouwishuponastar');
  const onLearnMoreClick = () => onClickExternalLink('/how-it-works');
  const onLearnMoreCharityClick = () => onClickExternalLink('/charity-raffle');
  const onLearnMoreBonusDrawClick = () => onClickExternalLink('/bonus-draw');
  const onMoreWinnersClick = () => onClickExternalLink('/winners');

  const isLoading = prizeLoading
    || isHomePageDataloading
    || isTimelineWidgetLoading
    || isFooterWidgetLoading;

  return (
    <>
      <MetaTags />
      {isLoading ? (
        <CtaButtonLayout isDark>
          <Header raffle={raffle} />
          <PageLoader loading />
          <Footer insideComponent={true} widget={footerWidget}/>
        </CtaButtonLayout>
      ) : (
        <Main
          prize={null}
          prizeId={prizeId}
          raffle={raffle}
          raffles={raffles}
          subscriptionModels={subscriptionModels}
          activeRaffles={activeRaffles}
          isExpired={isExpired}
          data={isDoublePrize ? doublePrizeData : singlePrizeData}
          reviews={reviews}
          winners={winners}
          onCTAClick={onCTAClick}
          onLearnMoreClick={onLearnMoreClick}
          onLearnMoreCharityClick={onLearnMoreCharityClick}
          onLearnMoreBonusDrawClick={onLearnMoreBonusDrawClick}
          onMoreWinnersClick={onMoreWinnersClick}
          widgets={{ timelineWidget, footerWidget }}
        />
      )}
      <ToastContainer />
    </>
  );
}

export default App;
