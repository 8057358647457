import React from 'react';
import './styles.css';

const TrustBox = (props) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div className="container">
      {!props.isHeader ? (
        <div className="trustpilotWrap">
          <div className="wrapCustom">
            <div
              ref={ref}
              data-locale="en-GB"
              data-template-id={props.id ? '54ad5defc6454f065c28af8b' : '53aa8912dec7e10d38f59f36'}
              data-businessunit-id="5c24f2aaba775e0001b90a80"
              data-style-height={props.id ? '80px' : '30px'}
              data-style-width="100%"
              data-theme={props.isDark ? 'dark' : 'light'}
              data-stars="4,5"
            >
              <a
                className="trustpilot-link"
                href="https://www.trustpilot.com/review/rafflehouse.com"
                target="_blank"
                rel="noopener"
              >
                Trustpilot
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div
          ref={ref}
          data-locale="en-GB"
          data-template-id="5419b732fbfb950b10de65e5"
          data-businessunit-id="5c24f2aaba775e0001b90a80"
          data-style-height="18px"
          data-style-width="100%"
          data-theme={props.isDark ? 'dark' : 'light'}
        >
          <a
            className="trustpilot-link"
            href="https://uk.trustpilot.com/review/rafflehouse.com"
            target="_blank"
            rel="noopener"
          >
            Trustpilot
          </a>
        </div>
      )}
    </div>
  );
};

export default TrustBox;
