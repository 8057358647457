import React from 'react';
import FlipButton from "../FlipButton/FlipButton";
import './styles.scss';

const BottomBigCTAButtonSection = (props) => {
  return (
    <div className={`bottom-big-cta-button-section`}>
      {/* <button className="big-cta-button">Enter Now</button> */}
      <FlipButton onClick={props.onClick} buttonText={props.buttonText} />
    </div>
  );
};

export default BottomBigCTAButtonSection;
