import React from 'react';
import './styles.scss';

const FlipButton = (props) => {
  return (
    <div className={`flip-button`} onClick={props.onClick}>
      <div className="flip-button-inner">
        <div className="flip-button-front">{props.buttonText ? props.buttonText : 'Enter now'}</div>
        <div className="flip-button-back">{props.buttonText ? props.buttonText : 'Enter now'}</div>
      </div>
    </div>
  );
};

export default FlipButton;
