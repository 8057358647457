import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import ReactHtmlParse from 'react-html-parser';
import { 
  TestimonialsButton, 
  TestimonialsContainer, 
  TestimonialsContent, 
  TestimonialsDescription, 
  TestimonialsHeading, 
  TestimonialsImage, 
  TestimonialsSlider,
  w,
  WinnerCard
} from 'raffle-house-uikit';

const parseDescription = (description) => {
  if (description && !/^<p>.*<\/p>$/.test(description)) {
    description = `<p>${description}</p>`;
  }
  return ReactHtmlParse(description);
};

export default function Testimonials({ winners, onMoreWinnersClick }) {
  const slides = useMemo(() => {
    return winners.reduce((slides, winner, index) => {
      if (winner.photoUrl) {
        const slide = (
          <div className='testimonials-winner-card' key={index}>
            <WinnerCard
              name={winner.name}
              description={winner.description ? parseDescription(winner.description) : ""}
              drawDate={dayjs(winner.drawDate).format("DD MMM YYYY")}
              photoUrl={process.env.REACT_APP_AWS_S3_URL + "winnersPhotos/" + winner.photoUrl + ".jpg"}
              ticketNumber={winner.ticketNumber}
            />
          </div>
        );
        return [...slides, slide];
      }
      return slides;
    }, []);
  }, [winners]);

  return (
    <TestimonialsContainer>
      <TestimonialsContent>
        <TestimonialsHeading>Be our next winner</TestimonialsHeading>
        <TestimonialsDescription>Trusted since 2018.</TestimonialsDescription>
        <TestimonialsSlider slides={slides}/>
      </TestimonialsContent>
      <TestimonialsButton onClick={onMoreWinnersClick}>More winners</TestimonialsButton>
      <TestimonialsImage placement='top-right' src={`${process.env.REACT_APP_AWS_S3_URL}testimonials/testimonial-image-1.png`} />
      <TestimonialsImage placement='bottom-left' src={`${process.env.REACT_APP_AWS_S3_URL}testimonials/testimonial-image-2.png`} />
    </TestimonialsContainer>
  )
}
