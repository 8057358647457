import React from 'react';

import './styles.scss';

const Loader = (props) => {
  return (
    <div className={`loader ${props.small ? 'small' : ''} ${props.reverseColor ? 'green-bg' : ''}`}>
      <div />
      <div />
      <div />
      <div />
      {props.loading ? (
        <React.Fragment>
          <span>{props.text ? props.text : ''}</span>
          <span>{props.loading}</span>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default Loader;
