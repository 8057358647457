import React from 'react'
import { 
  FaqAccordion, 
  FaqContainer, 
  FaqFooter, 
  FaqHeading, 
  FaqImage, 
  FaqInfo, 
  FaqInfoChat, 
  FaqInfoLinks, 
  FaqInfoText, 
  FaqInfoTitle, 
  FaqQuestions, 
} from 'raffle-house-uikit';
import man1 from '../../../extensions/images/redesign/man-1.jpg';
import man2 from '../../../extensions/images/redesign/man-2.jpg';
import man3 from '../../../extensions/images/redesign/man-3.jpg';
import { faqs } from '../../../lib/static-data'

export default function Faq() {
  return (
    <FaqContainer>
      <FaqQuestions>
        <FaqHeading>FAQs</FaqHeading>
        <FaqAccordion items={faqs} />
      </FaqQuestions>
      <FaqFooter>
        <FaqImage src={process.env.REACT_APP_AWS_S3_URL + "got-a-question/got-a-question-5.jpg"} />
        <FaqInfo>
          <FaqInfoTitle>Got a question?</FaqInfoTitle>
          <FaqInfoText>We're here for you just about 24/7</FaqInfoText>
          <FaqInfoChat title='Chat to Kathie, Alex and Agneta' images={[man1, man2, man3]} />
          <FaqInfoLinks />
        </FaqInfo>
      </FaqFooter>
    </FaqContainer>
  )
}
