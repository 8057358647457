const validateEmail = (email) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*\.\w{2,10}$/.test(email);
};

const validatePhone = (phone) => {
  return /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i.test(
    phone
  );
};

const validateName = (name) => {
  let message = '';
  let status = true;
  if (!name) {
    message = 'It should be more than 1 character';
    status = false;
    return { status, message };
  }
  const trimedNameStr = name.trim();
  if (name.length <= 1) {
    message = 'It should be more than 1 character';
    status = false;
    return { status, message };
  }
  if (name.length > 50) {
    message = 'It should be less than 50 characters';
    status = false;
    return { status, message };
  }
  if (!trimedNameStr.match(/^[^!@#$^%&*()"':;<>?/,|0-9]*$/)) {
    message = 'First name  is not valid';
    status = false;
    return { status, message };
  }
  return {
    message,
    status,
  };
};
const validateSurname = (surname) => {
  let message = '';
  let status = true;
  if (!surname) {
    message = 'It should be more than 1 character';
    status = false;
    return { status, message };
  }
  const trimedSurnameStr = surname.trim();
  if (surname.length <= 1) {
    message = 'It should be more than 1 character';
    status = false;
    return { status, message };
  }
  if (surname.length > 50) {
    message = 'It should be less than 50 characters';
    status = false;
    return { status, message };
  }
  if (!trimedSurnameStr.match(/^[^!@#$^%&*()"':;<>?/,|0-9]*$/)) {
    message = 'Last name is not valid';
    status = false;
    return { status, message };
  }
  return {
    message,
    status,
  };
};

const validatePassword = (password) => {
  let message = '';
  let status = true;
  const trimedPasswordStr = password.trim();
  if (password.length < 6) {
    message =
      'Please enter a password. Minimum 6 characters including at least 1 number.Please note, passwords are case sensitive';
    status = false;
    return { status, message };
  }
  if (password.length > 20) {
    message =
      'Password is invalid. Maximum 20 characters length including at least 1 letter and 1 number.';
    status = false;
    return { status, message };
  }
  if (!trimedPasswordStr.match(/^[^\s]*$/)) {
    message =
      'Please enter a password. Minimum 6 characters including at least 1 number.Please note, passwords are case sensitive';
    status = false;
    return { status, message };
  }
  if (
    !trimedPasswordStr.match(
      /^(?=.*\d)(?=.*[a-zA-Z])[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~|0-9a-zA-Z]{6,}$/
    )
  ) {
    message =
      'Password is invalid. Minimum 6 characters including at least 1 number.Please note, passwords are case sensitive';
    status = false;
    return { status, message };
  }
  return {
    message,
    status,
  };
};

export {
  validateEmail,
  validatePhone,
  validatePassword,
  validateName,
  validateSurname,
};
