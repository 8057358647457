import { useEffect } from 'react';
import TicketsService from '../services/tickets';
import { useState } from 'react';
import { error } from '../helpers/messages';

function useHomePageData() {
  const [isLoading, setIsLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [winners, setWinners] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    TicketsService.getAllHomePageData()
      .then((data) => {
        setReviews(data.reviews.reviews);
        setWinners(data.home.winners);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { isLoading, reviews, winners };
}

export default useHomePageData;
