import React from 'react'
import { 
  Button, 
  HowItWorksBackground, 
  HowItWorksCard, 
  HowItWorksContainer, 
  HowItWorksFooter, 
  HowItWorksList, 
  HowItWorksSlider, 
  HowItWorksSubtitle, 
  HowItWorksTitle 
} from 'raffle-house-uikit'
import { howItWorksItems } from '../../../lib/static-data';


const cards = howItWorksItems.map((item) => <HowItWorksCard {...item} />);

export default function HowItWorks({ onCTAClick }) {
  return (
    <HowItWorksContainer>
      <HowItWorksTitle>How it works</HowItWorksTitle>
      <HowItWorksList>{cards}</HowItWorksList>
      <HowItWorksSlider>{cards}</HowItWorksSlider>
      <HowItWorksSubtitle>Your win. Your dream. Your choice.</HowItWorksSubtitle>
      <HowItWorksFooter>
        <Button onClick={onCTAClick}>Enter now</Button>
      </HowItWorksFooter>
      <HowItWorksBackground src={process.env.REACT_APP_AWS_S3_URL + 'how-it-works/bg-line.svg'} />
    </HowItWorksContainer>
  )
}
