import React, { useState } from 'react';
import TitleText from '../common/TitleText/TitleText';
import SubtitleText from '../common/SubtitleText/SubtitleText';
import Loader from '../common/Loader/Loader';
import { validateEmail } from '../../../helpers/validators';
import { error, success } from '../../../helpers/messages';
import './styles.scss';
import TicketsService from '../../../services/tickets';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

const WinnerClub = (props) => {
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    emailError: '',
    email: '',
  });
  const handleChange = (event) => {
    const email = event.target.value.trim();
    setState({ ...state, email: email });
  };

  const submitEmail = () => {
    if (!isVerified) {
      error('Please complete ReCAPTCHA validation');
      return;
    }
    setLoading(true);
    const isValid = validateEmail(state.email);
    if (!isValid) {
      setState({ ...state, emailError: 'Invalid email' });
      error('Invalid email');
      setLoading(false);
      return;
    } else {
      setState((prev) => {
        return { ...prev, emailError: '' };
      });
    }

    TicketsService.turnOnEmailCommunicationAsync(state.email)
      .then(() => {
        setLoading(false);
        success('Subscription success');
      })
      .catch((err) => {
        setLoading(false);
        error(err.toString());
      });
  };

  const handleReCaptcha = (value) => {
    TicketsService.getRecaptchaVerifyAsync(value)
      .then((res) => {
        if(res.data && res.data.data.success) {
          setIsVerified(true);
        } else {
        setIsVerified(false)} 
      })
      .catch((err) => {
        setLoading(false);
        error(err.toString());
      });
  };

  return (
    <div className={'winner-club-wrapper-redesign'}>
      <div className='container'>
        {/* <div className="winner-club-title">The Dreamer’s Club</div> */}
        <TitleText isCentered as='h2' variant={'primary'} color='color-5'>
        Join the Dreamers’ Club
        </TitleText>
        <SubtitleText isCentered color='color-5'>
          Be the first to hear about new competitions and exclusive offers. Sign up to our newsletter today:
        </SubtitleText>

        <div className='input-section'>
          <div>
            <input type='text' name='email' placeholder='Your email address' onChange={handleChange} />
          </div>
          <ReCAPTCHA
            className="dream-club-recaptcha--mob"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={handleReCaptcha}
          />
          <button disabled={loading} onClick={submitEmail} className='dream-club-button'>
            {loading ? (
              <React.Fragment>
                <Loader small loading={loading} />
              </React.Fragment>
            ) : (
              'Sign up'
            )}
          </button>
        </div>
        <SubtitleText isCentered color='color-2'>
          Your data is safe & secure. We won't sell or misuse your personal information in any way.
        </SubtitleText>
        <ReCAPTCHA
          className='dream-club-recaptcha--desktop'
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={handleReCaptcha}
        />
      </div>
    </div>
  );
};

export default WinnerClub;
